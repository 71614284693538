// Here you can add other styles
.sidebar {
  background-color: #7d8083;
  color: #ffffff;
}

.sidebar-nav .nav-link{
  color: #fff;
}

.sidebar-nav .nav-link:hover {
  color: rgba(73, 165, 62, 0.87);
  text-decoration: none;
  font-weight: bold; // Added this line to make the text bold
  
  background: rgb(102 18 18 / 5%);
}
.sidebar-nav .nav-link.active {
  color: #fff;
  background: #7ac143 !important;
}
